<template>
  <div v-if="client">
    <div class="home__title__bottom">
      <div class="home__title__logo"><img :src="img" alt=""></div>
    </div>
    <div class="container">
      <ul class="home__statistics">
        <li
          v-for="(statistics, index) in statisticsList"
          :key="index"
        >
          <div class="statistics-icon" :class="statistics.img"></div><strong>{{statistics.number}}{{statistics.currency}}</strong>
          <p>{{statistics.title}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>


<style lang="scss">
  @import '../assets/home.css';
</style>


<script>
export default {
  name: 'Statistics',
  data:() => ({
    statisticsList: [
      {title: 'Заявок в обробці', number: '0', img: 'statistics-clock'},
      {title: 'Котирувань відправлено', number: '0', img: 'statistics-quotation'},
      {title: 'Відкритих угод', number: '0', img: 'statistics-agreements'},
      {title: 'Рахунків виставлено', number: '0', currency: '$', img: 'statistics-file'}
    ],
    
    globalTitle: [
      {title: 'Моя статистика', info: ''}
    ],
    client: '',
    img: 'https://crm-core.soft-pro.com/img/user-img.svg',
  }),


  async mounted(){
    //get current
    const res = await this.$store.dispatch('getCurrentUser');
    this.client = res.data.detail;
    if(this.client){
      this.img = this.client.img_url
      this.globalTitle[0].title = this.$root.langText.statistics.title
      this.globalTitle[0].info = this.client.login
      this.$root.title = this.globalTitle
    }

    this.statisticsList[0].title = this.$root.langText.statistics.inProcessing
    this.statisticsList[1].title = this.$root.langText.statistics.sentQuotes
    this.statisticsList[2].title = this.$root.langText.statistics.ordersOpen
    this.statisticsList[3].title = this.$root.langText.statistics.issuedInvoices

    
    const statistics = await this.$store.dispatch('getCurrentUserStatistics');
    var statisticsInfo = statistics.data.stats
    if(statisticsInfo){
      this.statisticsList[0].number = statisticsInfo.num_leads
      this.statisticsList[1].number = statisticsInfo.num_quotes
      this.statisticsList[2].number = statisticsInfo.num_orders
      this.statisticsList[3].number = statisticsInfo.inv_sum
    }
    var app = this
    // setTimeout(function(){
      if(!app.$root.edit.can_view_sum_income){
        app.statisticsList.splice(3, 1);
      }
    // }, 510);

    // this.globalTitle[0].title = this.client.name
    
  },
  metaInfo(){
    return {
      title: this.$title(this.$root.langText.menu.statistics)
    }
  },
}
</script>